
import { Component } from 'vue-property-decorator';
import { readUserProfile, readDashboardStatistics, readSiteCode, readSites } from '@/store/main/getters';
import { dispatchGetDashboardStatistics } from '@/store/main/actions';
import { store } from '@/store';
import VueBase from '@/components/VueBase.vue';

const dashboardRouteGuard = async (to, from, next) => {
  const siteCode = readSiteCode(store);
  if (siteCode) {
    await dispatchGetDashboardStatistics(store);
  }
  next();
};

@Component
export default class Dashboard extends VueBase {
  get greetedUser() {
    const userProfile = readUserProfile(this.$store);
    if (userProfile) {
      if (userProfile.full_name) {
        return userProfile.full_name;
      } else {
        return userProfile.email;
      }
    }
  }

  get totalNext7Days() {
    const statistics = readDashboardStatistics(this.$store);
    if (statistics) {
      return statistics.performances.total_next_7_days;
    }
    return '';
  }

  get numberNext7DaysWithShowtimes() {
    const statistics = readDashboardStatistics(this.$store);
    if (statistics) {
      return statistics.performances.number_next_7_days_with_showtimes;
    }
    return '';
  }

  get totalCallToday() {
    const statistics = readDashboardStatistics(this.$store);
    if (statistics) {
      return statistics.reporting.total_calls_today;
    }
    return '';
  }

  get totalSmsMessagesSent() {
    const statistics = readDashboardStatistics(this.$store);
    if (statistics) {
      return statistics.reporting.total_sms_messages_sent;
    }
    return '';
  }

  get totalCallsPast7Days() {
    const statistics = readDashboardStatistics(this.$store);
    if (statistics) {
      return statistics.reporting.total_calls_past_7_days;
    }
    return '';
  }

  get filmsListed() {
    const statistics = readDashboardStatistics(this.$store);
    if (statistics) {
      return statistics.films_listed;
    }
    return [];
  }

  get dashboardStatistics() {
    return readDashboardStatistics(this.$store);
  }

  get siteCode() {
    return readSiteCode(this.$store);
  }

  get site() {
    const site = this.sites.find((s) => s.code === this.siteCode);
    return site;
  }

  get foneNumber() {
    const site = this.site;
    if (site && site.line) {
      return site.line.inbound_number;
    }
    return '';
  }

  get sites() {
    return readSites(this.$store);
  }

  public beforeRouteEnter(to, from, next) {
    dashboardRouteGuard(to, from, next);
  }

  public beforeRouteUpdate(to, from, next) {
    dashboardRouteGuard(to, from, next);
  }
}
